import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import Avatar from "@material-ui/core/Avatar"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { statuses as authStatuses } from "../../hooks/auth"
import { useAppState } from "../../components/provider"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { formatValue } from "../profile"
import { db } from "../../configs/firebase"
import { reportToSentry } from ".."
import BigNumber from "bignumber.js"

const Guide = () => {
  const [wallets, setWallets] = useState([])
  const {
    auth: { status: authStatus, user, profile = {} },
  } = useAppState()
  const { buyback } = profile
  const { depositAddress, ppAcceptedAt, tosAcceptedAt, address, isAvailable } =
    buyback || {}
  const buybackRate = BigNumber(process.env.BUYBACK_RATE)

  useEffect(() => {
    if (authStatus === authStatuses.loggedIn) {
      const addressDoc = db
        .collection("profiles")
        .doc(user.uid)
        .collection("addresses")
      addressDoc
        .get()
        .then(snapshot => {
          const list = []
          snapshot.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() })
          })
          setWallets(list)
        })
        .catch(error => {
          reportToSentry(error, {
            user: { id: user.uid },
          })
        })
    } else if (user === null && authStatus === authStatuses.initial) {
      navigate("/")
    }
  }, [authStatus, user])

  if (user === null && authStatus === authStatuses.initial) {
    navigate("/")
    return null
  }
  if (
    authStatus === authStatuses.loggedIn &&
    !(address && ppAcceptedAt && tosAcceptedAt)
  ) {
    navigate("/profile")
    return null
  }
  if (!isAvailable && typeof window !== "undefined") {
    navigate("/profile")
    return null
  }

  return (
    <Layout>
      <SEO title="Stakeholder Profile" />
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer">
            <h1 className="Title">Buyback Procedure</h1>
            <section className="Panel textFormat">
              <div className="Text">
                <p>
                  As the final step for the buyback you should transfer your MSP
                  tokens to Mothership. The company will send the payment to
                  your withdrawal address in USDT (ERC20 token) the next
                  business day after it has received your MSP tokens.
                </p>

                <p>The payment will consist of two parts:</p>
                <ol>
                  <li>
                    <b>Payment for MSP Tokens.</b> 10.2 USDT per 1,000 MSP
                    tokens transferred to the company. The payment will be based
                    on the actual amount of MSP tokens received on the a
                    designated address. Mothership will cover the transaction
                    fees itself;
                  </li>
                  <li>
                    <b>Payment for your transfer fees.</b> Number of your
                    verified MSP addresses from where the company received MSP
                    tokens multiplied by the average MSP transfer fee of the
                    same day the transactions were made (but not more than 60
                    USDT / verified address).
                  </li>
                </ol>
              </div>
            </section>
            <section className="Panel textFormat">
              <h2 className="Title dark justify centered">
                <Avatar className="Avatar">1</Avatar> Deposit your MSP
              </h2>
              <hr />
              <p className="Text">
                Please send your MSP tokens to the following company’s address:
              </p>
              <h3 className="Lead dark">
                <strong>{depositAddress}</strong>
              </h3>
              <p className="Text">
                Mothership can only recognize transfers from the addresses that
                you have verified in your Stakeholder Profile. Here is the list
                of your verified addresses.
              </p>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Address</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>MSP</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>USDT</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wallets.map(({ id, msp }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {id}
                        </TableCell>
                        <TableCell align="right">{formatValue(msp)}</TableCell>
                        <TableCell align="right">
                          {buybackRate.times(msp).dividedBy(1e18).toFormat(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <p className="Text">
                If you wish to verify additional addresses and participate in
                the Buyback with the MSPs on those addresses, then please add
                them to your{" "}
                <Link
                  to="/holding-verification"
                  title="MSP holding verification"
                >
                  Stakeholder Profile
                </Link>
                . The addresses should be added to your Stakeholder Profile
                before you transfer MSP tokens from those addresses.
              </p>
            </section>
            <section className="Panel textFormat">
              <h2 className="Title dark justify centered">
                <Avatar className="Avatar">2</Avatar> Receive the payment
              </h2>
              <hr />
              <p className="Text">
                Mothership will make the payment in USDT to your account the
                next business day. It will also cover the transaction fees.
              </p>
              <p>The USDT will be transferred to this address:</p>
              <h3 className="Lead dark">
                <strong>{address}</strong>
              </h3>
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default Guide
